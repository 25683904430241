/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./api";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const transactionsDynamicSearch = /* GraphQL */ `query TransactionsDynamicSearch(
  $material: String!
  $size: String!
  $countryOfOrigin: String!
  $nextToken: String
) {
  transactionsDynamicSearch(
    material: $material
    size: $size
    countryOfOrigin: $countryOfOrigin
    nextToken: $nextToken
  ) {
    items {
      id
      createdTimestamp
      commandId
      material
      size
      countryOfOrigin
      location
      transactionType
      action
      bondedQuantity
      nonBondedQuantity
      flowId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsDynamicSearchQueryVariables,
  APITypes.TransactionsDynamicSearchQuery
>;
export const inventoryDynamicSearch = /* GraphQL */ `query InventoryDynamicSearch(
  $material: String
  $imbalanceFilter: String
  $nextToken: String
) {
  inventoryDynamicSearch(
    material: $material
    imbalanceFilter: $imbalanceFilter
    nextToken: $nextToken
  ) {
    items {
      material
      size
      countryOfOrigin
      location
      bondedQuantity
      nonBondedQuantity
      snapshotDiff
      lastSuccessWmsSnapshotQuantity1060
      lastSuccessWmsSnapshotTimestamp1060
      lastSuccessWmsSnapshotQuantity1065
      lastSuccessWmsSnapshotTimestamp1065
      lastSuccessWmsSnapshotQuantity1067
      lastSuccessWmsSnapshotTimestamp1067
      lastSuccessWmsSnapshotQuantity1064
      lastSuccessWmsSnapshotTimestamp1064
      lastSuccessWmsSnapshotQuantity1264
      lastSuccessWmsSnapshotTimestamp1264
      lastSuccessLedgerSnapshotTimestamp
      lastSuccessLedgerSnapshotQuantity
      wmsSnapshotQuantity
      ledgerSnapshotQuantity
      ledgerSnapshotTimestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InventoryDynamicSearchQueryVariables,
  APITypes.InventoryDynamicSearchQuery
>;
export const inventoryDetailSearch = /* GraphQL */ `query InventoryDetailSearch($material: String!, $size: String!, $coo: String!) {
  inventoryDetailSearch(material: $material, size: $size, coo: $coo) {
    items {
      material
      size
      countryOfOrigin
      location
      bondedQuantity
      nonBondedQuantity
      snapshotDiff
      lastSuccessWmsSnapshotQuantity1060
      lastSuccessWmsSnapshotTimestamp1060
      lastSuccessWmsSnapshotQuantity1065
      lastSuccessWmsSnapshotTimestamp1065
      lastSuccessWmsSnapshotQuantity1067
      lastSuccessWmsSnapshotTimestamp1067
      lastSuccessWmsSnapshotQuantity1064
      lastSuccessWmsSnapshotTimestamp1064
      lastSuccessWmsSnapshotQuantity1264
      lastSuccessWmsSnapshotTimestamp1264
      lastSuccessLedgerSnapshotTimestamp
      lastSuccessLedgerSnapshotQuantity
      wmsSnapshotQuantity
      ledgerSnapshotQuantity
      ledgerSnapshotTimestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InventoryDetailSearchQueryVariables,
  APITypes.InventoryDetailSearchQuery
>;
