import React, {useContext, useState} from "react";
import SnackbarContext from "../../providers/snackbar-context";
import {Box, IconButton, Text} from "@nike/eds";
import {InventoryOverviewTable} from "./InventoryOverviewTable";
import {edsSpace16} from "@nike/eds/dist/tokens";
import {InventoryOverviewSearchForm} from "./InventoryOverviewSearchForm";
import {InventoryOverviewSearchFormData} from "./model/InventoryOverviewSearchFormData";

export function InventoryOverview({inventoryService, LocationTracker}) {

    const snackbarCtx = useContext(SnackbarContext);
    const [loading, setLoading] = useState(false);
    const [currentSearchForm, setCurrentSearchForm] = useState({})
    let [searchResults, setSearchResults] = useState([])
    let [pagingTokens, setPagingTokens] = useState([])

    const searchHandler = (inventoryOverviewSearchFormData: InventoryOverviewSearchFormData, restore : boolean) => {
        setCurrentSearchForm(inventoryOverviewSearchFormData)
        if (restore) {
            pagingTokens = pagingTokens.slice(0, -1);
        } else {
            pagingTokens = []
        }
        searchResults = [];
        fetchData(inventoryOverviewSearchFormData, 'down');
    }

    const fetchData = (inventoryOverviewSearchFormData: InventoryOverviewSearchFormData, direction: string) => {
        setLoading(true);

        if (direction === 'up') {
            // remove next and current tokens from the pagingTokens array to retrieve previous set
            pagingTokens.pop()
            pagingTokens.pop()
        }

        if (!inventoryOverviewSearchFormData.materialNumbers || inventoryOverviewSearchFormData?.materialNumbers?.length === 0) {
            inventoryService.getAuditInventoryForMaterial("", inventoryOverviewSearchFormData.imbalanceFilter, pagingTokens.at(-1))
                .then(result => {
                    setLoading(false);
                    setSearchResults(result.data.inventoryDynamicSearch.items)
                    setPagingTokens(pagingTokens.concat(result.data.inventoryDynamicSearch.nextToken))
                })
                .catch(error => {
                    setLoading(false);
                    snackbarCtx.displayMsg(error.message, "error");
                });
        }

        if (inventoryOverviewSearchFormData.materialNumbers && inventoryOverviewSearchFormData?.materialNumbers?.length > 0) {
            // at the moment materialNumbers only has one item in the array
            inventoryService.getAuditInventoryForMaterial(inventoryOverviewSearchFormData.materialNumbers[0], inventoryOverviewSearchFormData.imbalanceFilter, pagingTokens.at(-1))
                .then(result => {
                    setLoading(false);
                    setSearchResults(result.data.inventoryDynamicSearch.items)
                    setPagingTokens(pagingTokens.concat(result.data.inventoryDynamicSearch.nextToken))
                })
                .catch(error => {
                    setLoading(false);
                    snackbarCtx.displayMsg(error.message, "error");
                });
        }
    };

    const titleStyle = {
        marginBottom: edsSpace16
    }

    return (
        <div>
            <Text style={titleStyle} font={"display-3"} as={"h3"}>INVENTORY</Text>

            <Box className="eds-grid eds-grid--m-cols-4">
                <Box className="eds-grid--m-col-3 nesg-grid nesg-grid-md-cols-2 nesg-grid-items-center">
                    <span className="eds-spacing--p-8" style={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
                        <IconButton icon="CaretLeft" variant="ghost" size="small" onClick={() => { searchHandler(currentSearchForm, false) }} label="top" />
                        <IconButton icon="CaretUp" variant="ghost" size="small" onClick={() => { fetchData(currentSearchForm, 'up') }} label="up"/>
                        <IconButton disabled={pagingTokens.at(-1) === null} icon="CaretDown" variant="ghost" size="small" onClick={() => { fetchData(currentSearchForm, 'down') }} label="down"/>
                        <span style={{opacity: '0.25'}}>page {pagingTokens.length}</span>
                    </span>
                    <InventoryOverviewTable inventoryService={inventoryService} searchResults={searchResults} loading={loading} />
                    <span className="eds-spacing--p-8" style={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
                        <IconButton icon="CaretLeft" variant="ghost" size="small" onClick={() => { searchHandler(currentSearchForm, false) }} label="top" />
                        <IconButton icon="CaretUp" variant="ghost" size="small" onClick={() => { fetchData(currentSearchForm, 'up') }} label="up"/>
                        <IconButton disabled={pagingTokens.at(-1) === null} icon="CaretDown" variant="ghost" size="small" onClick={() => { fetchData(currentSearchForm, 'down') }} label="down"/>
                        <span style={{opacity: '0.25'}}>page {pagingTokens.length}</span>
                    </span>
                </Box>
                <InventoryOverviewSearchForm onSearch={searchHandler} />
                <LocationTracker location="Inventory Overview" sublocation="Overview" />
            </Box>
        </div>
    )

}