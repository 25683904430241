export function mapTransactionType(transactionType : string, action : string): string {
    switch(transactionType) {
        case "RL01": {
            return "Goods Receipt"
        }
        case "WR01": {
            return "Warehouse Removal"
        }
        case "WE01": {
            return "Warehouse Entry"
        }
        case "ADJ01": {
            if (action === "CREATE") {
                return "Positive adjustment";
            } else if (action === "REMOVE") {
                return "Negative adjustment";
            }
            return "Adjustment";
        }
        default: {
            return "Undefined " + transactionType
        }
    }
}