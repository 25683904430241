import React, {useContext, useState} from "react";
import {Button, Text, Box, Modal} from "@nike/eds";
import {useParams} from "react-router-dom";
import {InventoryService} from "../../shared/InventoryService";
import {CustomsStatus, Type} from "../../../graphql/audit-overview-api/api";
import SnackbarContext from "../../../providers/snackbar-context";
import {edsSpace16} from "@nike/eds/dist/tokens";
import {useOktaAuth} from "@okta/okta-react";

interface QuantityAdjustmentModalProps {
    isOpen: boolean;
    onClose: () => void;
    quantity: number;
    adjustmentType: string;
    customsStatus: string;
    reason: string;
    comment: string;
    inventoryService: InventoryService
}

function QuantityAdjustmentModal({
                                     isOpen,
                                     onClose,
                                     quantity,
                                     adjustmentType,
                                     customsStatus,
                                     reason,
                                     comment,
                                     inventoryService
                                 }: QuantityAdjustmentModalProps) {

    const params = useParams();
    const snackbarCtx = useContext(SnackbarContext);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const adjustmentAction = adjustmentType === "POSADJ" ? "Add" : "Remove";
    const bondedStatus = customsStatus === "BONDED" ? "bonded" : "non-bonded";
    const locationAction = adjustmentType === "POSADJ" ? "to" : "from";

    let source = "";
    const authState = useOktaAuth();

    if (authState && authState.authState && authState.authState.isAuthenticated) {
        const userid = authState.authState.accessToken?.claims?.sub;
        source = userid || "";
    }

    const formatCurrentDateTime = () => {
        const currentDateTime = new Date();
        const year = currentDateTime.getFullYear();
        const month = String(currentDateTime.getMonth() + 1).padStart(2, '0');
        const day = String(currentDateTime.getDate()).padStart(2, '0');
        const hours = String(currentDateTime.getHours()).padStart(2, '0');
        const minutes = String(currentDateTime.getMinutes()).padStart(2, '0');

        return `${year}${month}${day}${hours}${minutes}`;
    };

    const handleSubmit = () => {

        let requestType;
        let requestStatus;

        if (adjustmentType === "POSADJ") {
            requestType = Type.POSADJ
        } else {
            requestType = Type.NEGADJ
        }

        if (customsStatus === "BONDED") {
            requestStatus = CustomsStatus.BONDED
        } else {
            requestStatus = CustomsStatus.NON_BONDED
        }

        inventoryService.submitManualAdjustment(
            params.materialNumber as string,
            params.size as string,
            params.countryOfOrigin as string,
            "ELC",
            requestType,
            quantity,
            requestStatus,
            reason,
            comment,
            source).catch(error => {
            snackbarCtx.displayMsg("Failed to load inventory: " + error, "error");
        }).catch((error) => {snackbarCtx.displayMsg(error, "error")})

        setIsConfirmed(true)
    }

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={onClose}
            headerSlot={isConfirmed ? "Adjustment " + params.materialNumber + "_" + params.size + "_" + params.countryOfOrigin + "_" +
            formatCurrentDateTime() + " sent in" : "Confirm adjustment"}
            footerSlot={
                isConfirmed ? (<> </>) : (
                    <>
                        <Button variant="secondary" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleSubmit}>
                            Confirm
                        </Button>
                    </>
                )
            }
        >
            <Box>
                {isConfirmed ? (
                    <>
                        <Text>
                            Please note: processing the adjustment will take some time and a refresh of the page is
                            necessary
                        </Text>
                        <img src='../../../../public/rocket.svg' alt="Rocket Icon" style={{marginTop: edsSpace16}}/>
                    </>
                ) : (
                    <>
                        <Text>Are you sure you want to make the following adjustment?</Text>
                        <br />
                        <ul>
                            <li>
                                {adjustmentAction} {quantity} units {bondedStatus} {locationAction} ELC
                            </li>
                            <li>Reason: {reason}</li>
                            <li>Comment: {comment}</li>
                        </ul>
                    </>
                )}
            </Box>
        </Modal>
    );
}

export default QuantityAdjustmentModal;