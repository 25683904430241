import {Box, Button, ButtonGroup, Card, Chip, Divider, Icon, Text, Radio, RadioGroup} from "@nike/eds";
import {SinglelineSearchInput} from "../shared/search/SinglelineSearchInput";
import React, {useEffect, useState, useCallback} from "react";
import {edsSpace16} from "@nike/eds/dist/tokens";
import {InventoryOverviewSearchFormData} from "./model/InventoryOverviewSearchFormData";
import useSessionStorage from "../../common/hooks/useSessionStorage";

const emptySearchCriteria = (): { materialNumbers: string[], imbalanceFilter: string } => {
    return {
        materialNumbers: [],
        imbalanceFilter: "ALL"
    }
};

function InventoryOverviewSearchForm({onSearch}) {

    const [searchCriteriaState, setSearchCriteria] = useSessionStorage("searchCriteria", emptySearchCriteria());
    const [materialNumberInput, setMaterialNumberInput] = useState('');
    const [clearAllState, setClearAllState] = useState(false);

    const searchCriteria = searchCriteriaState.value;

    const clearSearchCriteria = () => {
        setSearchCriteria(emptySearchCriteria());
    }

    const clearAndReload = () => {
        clearSearchCriteria();
        setClearAllState(true);
    }

    const addMaterialHandler = () => {
        if (searchCriteria?.materialNumbers === undefined) {
            setSearchCriteria({
                ...searchCriteria,
                materialNumbers: []
            })
        }

        if (materialNumberInput !== "" && searchCriteria?.materialNumbers?.indexOf(materialNumberInput) < 0) {
            let matArray = searchCriteria?.materialNumbers;
            matArray.push(materialNumberInput);
            setSearchCriteria({
                ...searchCriteria,
                materialNumbers: matArray
            })
            setMaterialNumberInput('')
        }
    }

    const materialNumberInputChangeHandler = (event) => {
        setMaterialNumberInput(event.target.value)
    }

    const submitHandler = (event) => {
        event.preventDefault();
        doSearch();
    }

    const doSearch = useCallback((restore = false) => {
        let searchRequestData: InventoryOverviewSearchFormData = {
            materialNumbers: searchCriteria.materialNumbers,
            imbalanceFilter: searchCriteria.imbalanceFilter
        }
        onSearch(searchRequestData, restore);
    }, [searchCriteria.materialNumbers, searchCriteria.imbalanceFilter, onSearch]);

    useEffect(() => {
        if (searchCriteriaState.restored) {
            doSearch(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchCriteriaState.restored]);


    useEffect(() => {
        doSearch(false)
        setClearAllState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearAllState]);

    const filterStyle = {
        marginTop: "52px"
    }

    const titleStyle = {
        marginBottom: edsSpace16
    }

    const dividerStyle = {
        marginTop: edsSpace16,
        marginBottom: edsSpace16
    }

    const pointerStyle = {
        cursor: "pointer"
    }

    let selechtedImbalanceFilter = searchCriteria.imbalanceFilter;
    if (!selechtedImbalanceFilter) {
        selechtedImbalanceFilter = "ALL";
    }

    return (
        <Box className="eds-grid--m-col-1">
            <Card style={filterStyle}>
                <Text style={titleStyle} font={"title-1"} as={"h1"}>Filter</Text>
                <Box className="eds-grid eds-grid--m-cols-3">
                    <Text font={"title-6"} as={"h6"} className="eds-grid--m-col-1">Balance</Text>
                    <div className="eds-grid--m-col-2">
                        <RadioGroup label=""
                                name="imbalanceFilterRadioGroup"
                                valueSelected={selechtedImbalanceFilter}
                                onChange={e => setSearchCriteria({...searchCriteria, imbalanceFilter: e.target.value})}
                                >
                            <Radio label="All inventory" value="ALL" id="radio-1" />
                            <Radio label="Only inventory with imbalances" value="IMBALANCE" id="radio-2" />
                            <Radio label="Only inventory with no imbalances" value="INBALANCE" id="radio-3" />
                        </RadioGroup>
                    </div>

                    <Text font={"title-6"} as={"h6"} className="eds-grid--m-col-1">Material</Text>
                    <div className="eds-grid--m-col-2">
                        <SinglelineSearchInput
                            name="materialNumbers"
                            label=""
                            value={materialNumberInput}
                            onChange={materialNumberInputChangeHandler}
                            disabled={searchCriteria?.materialNumbers?.length > 0}
                            placeholder={'Enter Material'}
                            beforeSlot={<Icon name="Search"/>}
                            afterSlot={<Icon name="PlusCircle" onClick={addMaterialHandler} style={pointerStyle}/>}
                            width={300}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    addMaterialHandler();
                                }
                            }}
                        />
                    </div>
                </Box>

                <Box>
                    {searchCriteria?.materialNumbers?.length > 0 &&
                        <Chip
                            as="button"
                            afterSlot={<Icon name="Close"/>}
                            onClick={clearSearchCriteria}
                        >
                            {searchCriteria?.materialNumbers}
                        </Chip>
                    }
                </Box>


                <Divider style={dividerStyle}/>

                <ButtonGroup className="eds-grid eds-grid--m-cols-2">
                    <Button className="eds-grid--m-col-1" variant="primary" size="small" type="submit" onClick={submitHandler}>
                        Apply
                    </Button>
                    <Button className="eds-grid--m-col-1" variant="secondary" size="small" type="button"
                            onClick={clearAndReload}>
                        Clear All
                    </Button>
                </ButtonGroup>
            </Card>
        </Box>
    )
}

export {InventoryOverviewSearchForm};