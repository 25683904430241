import {Table, TableCell, TableHeading, Text, Tooltip} from "@nike/eds";
import React, {useEffect, useState} from "react";
import "./LocationDetail.css";
import DateTimeFormat from "../shared/DateTimeFormat";


const SnapshotDetailsBody = ({sku}) => {

    const elcSku = sku.length ? sku.find(item => "ELC" === item.location) : undefined;

    const getLedgerQuantity = () : number => {
        if (elcSku) {
            return elcSku.lastSuccessLedgerSnapshotQuantity;
        }
        return 0;
    }
    const getLedgerSnapshotTimestamp = () : string | undefined => {
        if (elcSku) {
            return elcSku.lastSuccessLedgerSnapshotTimestamp;
        }
        return undefined;
    }

    const propertyPrefix = "lastSuccessWmsSnapshotTimestamp";
    const dcs : any = [];
    Object.keys(elcSku).forEach((key, index) => {
        if (key.startsWith(propertyPrefix)) {
            const dc = key.substring(propertyPrefix.length);
            dcs.push({
                dc: dc,
                quantity: elcSku["lastSuccessWmsSnapshotQuantity" + dc],
                timestamp: elcSku["lastSuccessWmsSnapshotTimestamp" + dc]
            });
        }
    });

    const style = {
        background: "black"
    };

    return <>
        <table>
            <tr>
                <th style={style}>DC</th>
                <th style={style}>Quantity</th>
                <th style={style}>Snapshot timestamp</th>
            </tr>
            {dcs
                .sort((a, b) => {
                    const dca = a.dc;
                    const dcb = b.dc;
                    return dca.localeCompare(dcb);
                })
                .map(dc => (
                <tr>
                    <td style={style}>{dc.dc}</td>
                    <td style={style}>{dc.quantity}</td>
                    <td style={style}><DateTimeFormat timestamp={dc.timestamp} /></td>
                </tr>
            ))}
            <tr>
                <th style={style}>CI</th>
                <th style={style}>Quantity</th>
                <th style={style}>Snapshot timestamp</th>
            </tr>
            <tr>
                <td style={style}>ELC</td>
                <td style={style}>{getLedgerQuantity()}</td>
                <td style={style}><DateTimeFormat timestamp={getLedgerSnapshotTimestamp()} /></td>
            </tr>
        </table>
    </>
}

const SnapshotDetails = ({sku, children}) => {
    return <>
        <Tooltip
            bodySlot={<SnapshotDetailsBody sku={sku} />}
            placement={"bottom"}
            isDark={true}
        >
            {children}
        </Tooltip>
    </>
}

function LocationDetail({inventoryService, sku}) {

    let [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            var result = await inventoryService.getInventoryForMaterialSizeCoo(sku.material, sku.size, sku.countryOfOrigin);
            setSearchResults(result.data.inventoryDetailSearch.items);
        }
        fetchData();
    }, [inventoryService, sku]);


    const getLocationQuantity = (searchResults, location: string, status) : number | undefined => {
        if (searchResults.length) {
            let locationQuantity = searchResults.find(item => location === item.location);
            if (locationQuantity) {
                if ("total" === status) {
                    return locationQuantity.bondedQuantity + locationQuantity.nonBondedQuantity;
                }
                if ("bonded" === status) {
                    return locationQuantity.bondedQuantity;
                }
                if ("nonBonded" === status) {
                    return locationQuantity.nonBondedQuantity;
                }
                if ("ledgerSnapshotQuantity" === status) {
                    return locationQuantity.lastSuccessLedgerSnapshotQuantity;
                }
                if ("wmsSnapshotQuantity" === status) {
                    return locationQuantity.wmsSnapshotQuantity;
                }
                if ("snapshotDiff" === status) {
                    return locationQuantity.snapshotDiff;
                }
                if ("lastSuccessLedgerSnapshotQuantity" === status) {
                    return (locationQuantity.lastSuccessLedgerSnapshotQuantity) ? (locationQuantity.lastSuccessLedgerSnapshotQuantity) : 0;
                }
            }
        }
        return 0;
    }

    const getLocationSnashotTimestamp = (searchResults) : string | undefined => {
        if (searchResults.length) {
            let locationQuantity = searchResults.find(item => "ELC" === item.location);
            if (locationQuantity) {
                return locationQuantity.lastSuccessLedgerSnapshotTimestamp;
            }
        }
        return undefined;
    }

    const balancingSplitStyle = {
        borderLeft: "1px solid black"
    };

    return (
        <div className="locationDetail">
            <Table>
                <thead>
                    <tr>
                        <TableHeading>Location</TableHeading>
                        <TableHeading>Bonded<Text font="legal-1">Actual status</Text></TableHeading>
                        <TableHeading>Non-Bonded<Text font="legal-1">Actual status</Text></TableHeading>
                        <TableHeading>Total<Text font="legal-1">Actual status</Text></TableHeading>
                        <TableHeading style={balancingSplitStyle}>Balanced at</TableHeading>
                        <TableHeading>Customs Inventory</TableHeading>
                        <TableHeading>Physical Inventory</TableHeading>
                        <TableHeading>Difference</TableHeading>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <TableCell className="eds-table-key-column">ELC</TableCell>
                    <TableCell >{getLocationQuantity(searchResults, "ELC", "bonded")}</TableCell>
                    <TableCell >{getLocationQuantity(searchResults, "ELC", "nonBonded")}</TableCell>
                    <TableCell >{getLocationQuantity(searchResults, "ELC", "total")}</TableCell>
                    <TableCell style={balancingSplitStyle} >
                        <SnapshotDetails 
                                sku={searchResults}>
                            <DateTimeFormat timestamp={getLocationSnashotTimestamp(searchResults)} format="dd/MM/yyyy HH:mm" />
                        </SnapshotDetails>
                    </TableCell>
                    <TableCell >{getLocationQuantity(searchResults, "ELC", "lastSuccessLedgerSnapshotQuantity")}</TableCell>
                    <TableCell >{getLocationQuantity(searchResults, "ELC", "wmsSnapshotQuantity")}</TableCell>
                    <TableCell >{getLocationQuantity(searchResults, "ELC", "snapshotDiff")}</TableCell>
                    <TableCell>&nbsp;</TableCell>
                </tr>

                <tr>
                    <TableCell className="eds-table-key-column">Transit</TableCell>
                    <TableCell>{getLocationQuantity(searchResults, "TRANSIT", "bonded")}</TableCell>
                    <TableCell>{getLocationQuantity(searchResults, "TRANSIT", "nonBonded")}</TableCell>
                    <TableCell>{getLocationQuantity(searchResults, "TRANSIT", "total")}</TableCell>
                    <TableCell style={balancingSplitStyle}>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                </tr>

                </tbody>
            </Table>
        </div>
    )
}

export {LocationDetail};
