import {Button, Card, Skeleton, Text} from "@nike/eds";
import {edsSpace16} from "@nike/eds/dist/tokens";
import React, {useContext, useEffect, useState} from "react";
import {TransactionHistoryTable} from "./transaction-history/TransactionHistoryTable";
import SnackbarContext from "../../providers/snackbar-context";
import {useNavigate, useParams} from "react-router-dom";
import {CaretRight} from "@nike/nike-design-system-icons";
import "./InventoryItemDetail.css";
import {QuantityAdjustmentForm} from "./adjust-quantity/QuantityAdjustmentForm";

function InventoryItemDetail({inventoryService, LocationTracker}) {

    const params = useParams();

    const snackbarCtx = useContext(SnackbarContext);
    const [loadingDetail, setLoadingDetail] = useState(false);
    const [loadingTransactions, setLoadingTransactions] = useState(false);
    const [showQuantityAdjustment, setShowQuantityAdjustment] = useState(false);
    let [detailResults, setDetailResults] = useState([])
    let [transactionHistoryResults, setTransactionHistoryResults] = useState([])

    useEffect(() => {
        setLoadingDetail(true)
        setLoadingTransactions(true)

        inventoryService
            .getInventoryForMaterialSizeCoo(params.materialNumber, params.size, params.countryOfOrigin)
            .then(result => {
                setDetailResults(result.data.inventoryDetailSearch.items);
                setLoadingDetail(false);
            })
            .catch(error => {
                snackbarCtx.displayMsg("Failed to load inventory: " + error, "error");
            })

        inventoryService
            .getTransactionsForMaterialSizeCoo(params.materialNumber, params.size, params.countryOfOrigin)
            .then(result => {
                setTransactionHistoryResults(result.data.transactionsDynamicSearch.items);
                setLoadingTransactions(false);
            })
            .catch(error => {
                snackbarCtx.displayMsg("Failed to load transactions: " + error, "error");
            })

    }, [inventoryService, params.materialNumber, params.size, params.countryOfOrigin, snackbarCtx]);

    let navigate = useNavigate();

    const routeChange = () => {
        let path = '/inventory-overview';
        navigate(path);
    }

    const titleStyle = {
        marginBottom: edsSpace16
    }

    const boldStyle = {
        fontWeight: "bold"
    }

    const tableDiv = {
        marginTop: edsSpace16
    };

    const marginBottom2Rem = {
        marginBottom: "2rem"
    }

    const detailViewMargin = {
        marginBottom: "1rem"
    }

    const stockTitle = {
        fontWeight: "bold",
        marginBottom: "2rem"
    }

    const getLocationQuantity = (detailResults, location: string, status) : number => {
        if (detailResults.length) {
            let locationQuantity = detailResults.find(item => location === item.location);
            if (locationQuantity) {
                if ("total" === status) {
                    return locationQuantity.bondedQuantity + locationQuantity.nonBondedQuantity;
                }
                if ("bonded" === status) {
                    return locationQuantity.bondedQuantity;
                }
                if ("nonBonded" === status) {
                    return locationQuantity.nonBondedQuantity;
                }
            }
        }
        return 0;
    }

    const handleAdjustQuantityClick = () => {
        setShowQuantityAdjustment(true);
    }

    return (
        <div>
            <LocationTracker location="Inventory Overview" sublocation="Detail"
                            material={params.materialNumber} size={params.size} coo={params.countryOfOrigin} />
            <Text style={titleStyle} font={"display-3"} as={"h3"}>ITEM DETAILS</Text>
            <Button style={marginBottom2Rem} variant={"ghost"} onClick={() => routeChange()}>
                Inventory
                <CaretRight size={"s"}/>
            </Button>
            {loadingDetail &&
                <div className="eds-grid eds-grid--m-cols-3">
                    <div className="eds-grid eds-grid--m-col-1">
                        <Card>
                            <div style={detailViewMargin} className="eds-grid eds-grid--m-cols-2">
                                <Skeleton height={24} width={"100%"}/>
                                <Skeleton height={24} width={"100%"}/>
                            </div>
                            <div style={detailViewMargin} className="eds-grid eds-grid--m-cols-2">
                                <Skeleton height={24} width={"100%"}/>
                                <Skeleton height={24} width={"100%"}/>
                            </div>

                            <div style={detailViewMargin} className="eds-grid eds-grid--m-cols-2">
                                <Skeleton height={24} width={"100%"}/>
                                <Skeleton height={24} width={"100%"}/>
                            </div>
                        </Card>
                    </div>
                    <div className="eds-grid eds-grid--m-col-2">
                        <Card title={"Stock"}>
                            <Skeleton height={24} width={"100%"}/>
                            <Skeleton height={24} width={"100%"}/>
                            <Skeleton height={24} width={"100%"}/>
                        </Card>
                    </div>
                </div>
            }
            {!loadingDetail &&
                <div style={marginBottom2Rem} className="eds-grid eds-grid--m-cols-3">
                    <div className="eds-grid eds-grid--m-row-1">
                        <div className="eds-grid_item eds-grid_item--m-1-2">
                            <Card>
                                <div style={detailViewMargin} className="eds-grid eds-grid--m-cols-2">
                                    <Text>Material</Text>
                                    <Text style={boldStyle}>{params.materialNumber}</Text>
                                </div>
                                <div style={detailViewMargin} className="eds-grid eds-grid--m-cols-2">
                                    <Text>Size</Text>
                                    <Text style={boldStyle}>{params.size}</Text>
                                </div>
                                <div className="eds-grid eds-grid--m-cols-2">
                                    <Text>Country Of Origin</Text>
                                    <Text style={boldStyle}>{params.countryOfOrigin}</Text>
                                </div>
                            </Card>
                        </div>

                        <div className="eds-grid_item eds-grid_item--m-1-2">
    `                        <Button className="eds-grid--m-col-1" variant="primary" size="small" type="submit" onClick={handleAdjustQuantityClick}>
                                Adjust Quantity
                            </Button>`
                        </div>
                    </div>



                    <div className="eds-grid eds-grid--m-col-2">
                        <Card>
                            <div className="eds-grid eds-grid--m-cols-4  eds-spacing--mb-12 eds-grid-b-line">
                                <Text style={stockTitle} font={"subtitle-1"}>Location</Text>
                                <Text style={stockTitle} font={"subtitle-1"}>Bonded<Text font="legal-1">Actual status</Text></Text>
                                <Text style={stockTitle} font={"subtitle-1"}>Non-Bonded<Text font="legal-1">Actual status</Text></Text>
                                <Text style={stockTitle} font={"subtitle-1"}>Total<Text font="legal-1">Actual status</Text></Text>
                            </div>
                            <div className="eds-grid eds-grid--m-cols-4 eds-spacing--my-12">
                                <Text font="body-3">Transit</Text>
                                <Text font="body-3" style={boldStyle}>{getLocationQuantity(detailResults, "TRANSIT", "bonded")}</Text>
                                <Text font="body-3" style={boldStyle}>{getLocationQuantity(detailResults, "TRANSIT", "nonBonded")}</Text>
                                <Text font="body-3" style={boldStyle}>{getLocationQuantity(detailResults, "TRANSIT", "total")}</Text>
                            </div>
                            <div className="eds-grid eds-grid--m-cols-4 eds-spacing--my-12">
                                <Text font="body-3">ELC</Text>
                                <Text font="body-3" style={boldStyle}>{getLocationQuantity(detailResults, "ELC", "bonded")}</Text>
                                <Text font="body-3" style={boldStyle}>{getLocationQuantity(detailResults, "ELC", "nonBonded")}</Text>
                                <Text font="body-3" style={boldStyle}>{getLocationQuantity(detailResults, "ELC", "total")}</Text>
                            </div>
                        </Card>
                    </div>
                </div>
            }

            {!showQuantityAdjustment && (
                <div style={tableDiv}>
                    <Text style={titleStyle} font={"title-3"} as={"h3"}>Transaction History</Text>
                    <TransactionHistoryTable searchResults={transactionHistoryResults} loading={loadingTransactions}/>
                </div>
            )}

            {showQuantityAdjustment && (
                <div>
                    <QuantityAdjustmentForm inventoryService={inventoryService} />
                </div>
            )}

        </div>
    )
}

export {InventoryItemDetail};