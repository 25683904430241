/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export enum Type {
  POSADJ = "POSADJ",
  NEGADJ = "NEGADJ",
}


export enum CustomsStatus {
  BONDED = "BONDED",
  NON_BONDED = "NON_BONDED",
}


export type SupportAPIResponse = {
  __typename: "SupportAPIResponse",
  message?: string | null,
  success?: boolean | null,
};

export type AuditTransactionConnection = {
  __typename: "AuditTransactionConnection",
  items?:  Array<AuditTransaction | null > | null,
  nextToken?: string | null,
};

export type AuditTransaction = {
  __typename: "AuditTransaction",
  id: string,
  createdTimestamp?: string | null,
  commandId?: string | null,
  material?: string | null,
  size?: string | null,
  countryOfOrigin?: string | null,
  location?: string | null,
  transactionType?: string | null,
  action?: string | null,
  bondedQuantity?: number | null,
  nonBondedQuantity?: number | null,
  flowId?: string | null,
};

export type AuditInventoryConnection = {
  __typename: "AuditInventoryConnection",
  items?:  Array<AuditInventory | null > | null,
  nextToken?: string | null,
};

export type AuditInventory = {
  __typename: "AuditInventory",
  material: string,
  size: string,
  countryOfOrigin?: string | null,
  location: string,
  bondedQuantity?: number | null,
  nonBondedQuantity?: number | null,
  snapshotDiff?: number | null,
  lastSuccessWmsSnapshotQuantity1060?: number | null,
  lastSuccessWmsSnapshotTimestamp1060?: string | null,
  lastSuccessWmsSnapshotQuantity1065?: number | null,
  lastSuccessWmsSnapshotTimestamp1065?: string | null,
  lastSuccessWmsSnapshotQuantity1067?: number | null,
  lastSuccessWmsSnapshotTimestamp1067?: string | null,
  lastSuccessWmsSnapshotQuantity1064?: number | null,
  lastSuccessWmsSnapshotTimestamp1064?: string | null,
  lastSuccessWmsSnapshotQuantity1264?: number | null,
  lastSuccessWmsSnapshotTimestamp1264?: string | null,
  lastSuccessLedgerSnapshotTimestamp?: string | null,
  lastSuccessLedgerSnapshotQuantity?: number | null,
  wmsSnapshotQuantity?: number | null,
  ledgerSnapshotQuantity?: number | null,
  ledgerSnapshotTimestamp?: string | null,
};

export type SubmitManualAdjustmentMutationVariables = {
  material?: string | null,
  size?: string | null,
  countryOfOrigin?: string | null,
  location?: string | null,
  type?: Type | null,
  quantity?: number | null,
  customsStatus?: CustomsStatus | null,
  reason?: string | null,
  comment?: string | null,
  source?: string | null,
};

export type SubmitManualAdjustmentMutation = {
  submitManualAdjustment?:  {
    __typename: "SupportAPIResponse",
    message?: string | null,
    success?: boolean | null,
  } | null,
};

export type TransactionsDynamicSearchQueryVariables = {
  material: string,
  size: string,
  countryOfOrigin: string,
  nextToken?: string | null,
};

export type TransactionsDynamicSearchQuery = {
  transactionsDynamicSearch?:  {
    __typename: "AuditTransactionConnection",
    items?:  Array< {
      __typename: "AuditTransaction",
      id: string,
      createdTimestamp?: string | null,
      commandId?: string | null,
      material?: string | null,
      size?: string | null,
      countryOfOrigin?: string | null,
      location?: string | null,
      transactionType?: string | null,
      action?: string | null,
      bondedQuantity?: number | null,
      nonBondedQuantity?: number | null,
      flowId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type InventoryDynamicSearchQueryVariables = {
  material?: string | null,
  imbalanceFilter?: string | null,
  nextToken?: string | null,
};

export type InventoryDynamicSearchQuery = {
  inventoryDynamicSearch?:  {
    __typename: "AuditInventoryConnection",
    items?:  Array< {
      __typename: "AuditInventory",
      material: string,
      size: string,
      countryOfOrigin?: string | null,
      location: string,
      bondedQuantity?: number | null,
      nonBondedQuantity?: number | null,
      snapshotDiff?: number | null,
      lastSuccessWmsSnapshotQuantity1060?: number | null,
      lastSuccessWmsSnapshotTimestamp1060?: string | null,
      lastSuccessWmsSnapshotQuantity1065?: number | null,
      lastSuccessWmsSnapshotTimestamp1065?: string | null,
      lastSuccessWmsSnapshotQuantity1067?: number | null,
      lastSuccessWmsSnapshotTimestamp1067?: string | null,
      lastSuccessWmsSnapshotQuantity1064?: number | null,
      lastSuccessWmsSnapshotTimestamp1064?: string | null,
      lastSuccessWmsSnapshotQuantity1264?: number | null,
      lastSuccessWmsSnapshotTimestamp1264?: string | null,
      lastSuccessLedgerSnapshotTimestamp?: string | null,
      lastSuccessLedgerSnapshotQuantity?: number | null,
      wmsSnapshotQuantity?: number | null,
      ledgerSnapshotQuantity?: number | null,
      ledgerSnapshotTimestamp?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type InventoryDetailSearchQueryVariables = {
  material: string,
  size: string,
  coo: string,
};

export type InventoryDetailSearchQuery = {
  inventoryDetailSearch?:  {
    __typename: "AuditInventoryConnection",
    items?:  Array< {
      __typename: "AuditInventory",
      material: string,
      size: string,
      countryOfOrigin?: string | null,
      location: string,
      bondedQuantity?: number | null,
      nonBondedQuantity?: number | null,
      snapshotDiff?: number | null,
      lastSuccessWmsSnapshotQuantity1060?: number | null,
      lastSuccessWmsSnapshotTimestamp1060?: string | null,
      lastSuccessWmsSnapshotQuantity1065?: number | null,
      lastSuccessWmsSnapshotTimestamp1065?: string | null,
      lastSuccessWmsSnapshotQuantity1067?: number | null,
      lastSuccessWmsSnapshotTimestamp1067?: string | null,
      lastSuccessWmsSnapshotQuantity1064?: number | null,
      lastSuccessWmsSnapshotTimestamp1064?: string | null,
      lastSuccessWmsSnapshotQuantity1264?: number | null,
      lastSuccessWmsSnapshotTimestamp1264?: string | null,
      lastSuccessLedgerSnapshotTimestamp?: string | null,
      lastSuccessLedgerSnapshotQuantity?: number | null,
      wmsSnapshotQuantity?: number | null,
      ledgerSnapshotQuantity?: number | null,
      ledgerSnapshotTimestamp?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};
