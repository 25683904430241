import React from 'react';
import './index.css';
import App from './App';
import {SnackBarContextProvider} from "./providers/snackbar-context";
import * as serviceWorker from './serviceWorker';
import * as ReactDOMClient from 'react-dom/client';
import "@nike/eds/dist/index.css";
import {BrowserRouter} from "react-router-dom";

let inventoryOverviewContainer = null;
let root = null;

///for testing purposes
// let containerId = 'root'
// requestOverviewContainer = document.getElementById(containerId);
// root = ReactDOMClient.createRoot(requestOverviewContainer);
// root.render(
//     <React.StrictMode>
//         <BrowserRouter>
//             <App />
//         </BrowserRouter>
//     </React.StrictMode>
// );
///


window.renderInventoryOverview = (containerId, history, {locationTracker}) => {
    if (!inventoryOverviewContainer) {
        inventoryOverviewContainer = document.getElementById(containerId);
        root = ReactDOMClient.createRoot(inventoryOverviewContainer);
        root.render(
            <React.StrictMode>
                <BrowserRouter>
                    <SnackBarContextProvider>
                        <App history={history} locationTracker={locationTracker} />
                    </SnackBarContextProvider>
                </BrowserRouter>
            </React.StrictMode>
        );
        serviceWorker.unregister();
    }
};

window.unmountInventoryOverview = containerId => {
    root.unmount()
    inventoryOverviewContainer = null;
    root = null;
};

if (!document.getElementById('InventoryOverview-container')) {
    //root.render(<App />);
    serviceWorker.unregister();
}


