/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./api";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const submitManualAdjustment = /* GraphQL */ `mutation SubmitManualAdjustment(
  $material: String
  $size: String
  $countryOfOrigin: String
  $location: String
  $type: Type
  $quantity: Int
  $customsStatus: CustomsStatus
  $reason: String
  $comment: String
  $source: String
) {
  submitManualAdjustment(
    material: $material
    size: $size
    countryOfOrigin: $countryOfOrigin
    location: $location
    type: $type
    quantity: $quantity
    customsStatus: $customsStatus
    reason: $reason
    comment: $comment
    source: $source
  ) {
    message
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitManualAdjustmentMutationVariables,
  APITypes.SubmitManualAdjustmentMutation
>;
